import _get from 'lodash/get';
import _map from 'lodash/map';
import slugify from 'slugify';

import BRANCH_TYPE from '@lumirental/lumi-web-shared/lib/constants/app/BRANCH_TYPE';

import { PAGE_NAMES, SCREEN_NAMES, SEO_PAGE_ROUTES } from '@/constants';

/**
 * apply smooth scrolling to reach specific element.
 * @param {String} eleId
 */
export const manageSmoothScrolling = (eleId: string): void => {
  const ref = document.querySelector(eleId);

  if (ref) {
    window.scrollTo({
      top: ref.getBoundingClientRect().top,
      behavior: 'smooth',
    });
  }
};

/**
 * Returns Breadcrumb schema structure for the page
 * @param {Array} breadcrumbList
 * @param {string} language
 * @returns {string}
 */
export const getBreadcrumbSchema = (
  breadcrumbList: any[],
  language: string,
  origin: string,
): string => {
  const schemaObj = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Home',
        item: origin,
      },
    ],
  };

  breadcrumbList.forEach((breadcrumb: any) => {
    const dataObj = {
      '@type': 'ListItem',
      position: _get(breadcrumb, 'id', 0) + 1,
      name: _get(breadcrumb, 'label', ''),
      item: `${origin}/${language}/${_get(breadcrumb, 'path', '')}`,
    };
    schemaObj.itemListElement.push(dataObj);
  });
  return JSON.stringify(schemaObj);
};

/**
 * Return FAQ Schema structure for the page
 * https://developers.google.com/search/docs/advanced/structured-data/faqpage
 * @param {Array} faqList
 * @returns {string}
 */
export const getFAQSchema = (faqList: any[]): string => {
  const schemaObj: Record<string, any> = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [],
  };

  faqList.forEach((faq: any) => {
    schemaObj.mainEntity.push({
      '@type': 'Question',
      name: _get(faq, 'question', ''),
      acceptedAnswer: {
        '@type': 'Answer',
        text: _get(faq, 'answer', ''),
      },
    });
  });
  return JSON.stringify(schemaObj);
};

/**
 * Returns Airport Branch schema structure for the page
 * @param {Object} airportDetails
 * @returns {string}
 */
export const getAirportBranchSchema = (
  airportDetails: {
    slug: any;
    phoneNumber: any;
    cityName: any;
    directions: any;
    timingSummary: any;
  },
  language: string,
): string => {
  const { slug, phoneNumber, cityName, directions, timingSummary } =
    airportDetails;

  const coordinates = directions?.substring(
    directions?.indexOf('@') + 1,
    directions?.lastIndexOf(','),
  );
  const [latitude, longitude] = coordinates?.split(',');

  const openingHoursDetails = getTimingSchema(timingSummary);

  const schemaObj = {
    '@context': 'https://schema.org',
    '@type': 'AutoRental',
    name: 'Lumi Rental',
    image: 'https://lumirental.com/images/svg/lumi-logo.svg',
    '@id': `https://lumirental.com/${language}/car-rental/airports/${slug}`,
    url: `https://lumirental.com/${language}`,
    telephone: `${phoneNumber}`,
    address: {
      '@type': 'PostalAddress',
      addressLocality: `${cityName}`,
      addressCountry: 'SA',
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: Number(latitude),
      longitude: Number(longitude),
    },
    openingHoursSpecification: openingHoursDetails,
  };

  return JSON.stringify(schemaObj);
};

/**
 * Returns Opening Hours schema structure
 * @param {Array} timingSummary
 * @returns {Array|object}
 */
const getTimingSchema = (timingSummary: any[]) => {
  const fullWeek = timingSummary[0]?.dayLabel;
  let openingHours: Record<string, any> = [];

  if (fullWeek === 'all week') {
    openingHours = {
      '@type': 'OpeningHoursSpecification',
      dayOfWeek: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
      opens: timingSummary[0]?.start,
      closes:
        timingSummary[0]?.end === '00:00' ? '23:59' : timingSummary[0]?.end,
    };
  } else {
    openingHours = timingSummary?.map((timing: any) => {
      const dayLabel = _get(timing, 'dayLabel', '');
      const daysLabels = _get(timing, 'daysLabels', []);
      const start = _get(timing, 'start', '');
      const end = _get(timing, 'end', '');

      const timeLabel = _get(timing, 'timeLabel', '');
      const isFullDay = timeLabel === '24 hours';

      const isClosed = timeLabel === 'CLOSED';

      let capitalizedDaysLabels = [];
      if (daysLabels?.length) {
        capitalizedDaysLabels = _map(daysLabels, (el) => {
          return el?.charAt(0)?.toUpperCase() + el?.slice(1);
        });
      } else {
        capitalizedDaysLabels =
          dayLabel?.charAt(0)?.toUpperCase() + dayLabel?.slice(1);
      }

      const dayCloseAt = end === '00:00' || isFullDay ? '23:59' : end;
      const closes = isClosed ? '00:00' : dayCloseAt;

      return {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: capitalizedDaysLabels,
        opens: isFullDay || isClosed ? '00:00' : start,
        closes,
      };
    });
  }

  return openingHours;
};

/**
 * Returns page meta data
 * @param {object} data
 * @returns {object}
 */
export function getMetaData(data: {
  metadescription: string;
  metatitle: string;
  pageheading: string;
  pagesubheading: string;
  faqs: any;
  sections: any;
}): {
  description: string;
  title: string;
  pageHeading: string;
  pageSubheading: string;
  faqs: any;
  sections: any;
} {
  const description = _get(data, 'metadescription', '');
  const title = _get(data, 'metatitle', '');
  const pageHeading = _get(data, 'pageheading', '');
  const pageSubheading = _get(data, 'pagesubheading', '');
  const faqs = data?.faqs;
  const sections = data?.sections;
  return {
    description,
    title,
    pageHeading,
    pageSubheading,
    faqs,
    sections,
  };
}

/**
 * Returns screen name
 * @param {string} currentPage
 * @returns {string}
 */
export const getScreenName = (currentPage: string): string => {
  if (currentPage === PAGE_NAMES.CAR_RENTAL) {
    return SCREEN_NAMES.CAR_RENTAL;
  }
  if (currentPage === PAGE_NAMES.ALL_AIRPORTS) {
    return SCREEN_NAMES.ALL_AIRPORTS;
  }
  return SCREEN_NAMES.AIRPORT_LANDING;
};

/**
 * Returns slug from given str.
 * @param {string} str
 * @returns {string}
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getSlug = (str: string) =>
  slugify(str, {
    remove: /[$*_+~.()'"!\-:@]+/g,
  });

/**
 * Returns branch route by the given branch type.
 * @param {String} branchType
 * @returns {String}
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getBranchRoute = (branchType: string) => {
  return branchType === BRANCH_TYPE.AIRPORT
    ? SEO_PAGE_ROUTES.AIRPORT
    : SEO_PAGE_ROUTES.CITY_BRANCH;
};

export const getAppEnv = (): string => {
  return process.env.APP_ENV || 'production';
};
