/**
 * All GTM events for desktop app are here
 * There are LWEB specific events
 */

import formatISO from 'date-fns/formatISO';
import _get from 'lodash/get';

import pushDataLayer from '@lumirental/lumi-web-sdk/dist/utils/gtm/pushDataLayer';
import get24hTime from '@lumirental/lumi-web-shared/lib/utils/get24hTime';
// import getRentalDuration from '@lumirental/lumi-web-shared/lib/utils/getRentalDuration';

/**
 * Event triggered by tapping on some link
 * @param {string} screenName
 * @param {string} eventName
 */
export function onTapEvent(screenName, eventName) {
  const eventData = {
    event: eventName,
    screen_name: screenName,
  };
  console.log(`** gtm event ${eventName}: `, eventData);
  pushDataLayer(eventData);
}

/**
 * When an city branch is clicked
 * @param {string} screenName
 * @param {string} name
 */
export function cityBranchClicked(screenName, name) {
  const eventData = {
    event: 'SEO_branch_clicked',
    screen_name: screenName,
    branch_name: name,
    branch_type: 'city branch',
  };
  console.log('** gtm event SEO_branch_clicked: ', eventData);
  pushDataLayer(eventData);
}

/**
 * When a city is clicked
 * @param {string} screenName
 * @param {string} name
 */
export function cityClicked(screenName, name) {
  const eventData = {
    event: 'SEO_city_selected',
    screen_name: screenName,
    city_name: name,
  };
  console.log('** gtm event SEO_city_selected : ', eventData);
  pushDataLayer(eventData);
}

/**
 * When View all branches is clicked
 * @param {string} screenName
 */
export function viewAllClicked(screenName, type) {
  const eventData = {
    event: 'SEO_View_all_clicked',
    screen_name: screenName,
    type,
  };
  console.log('** gtm event SEO_View_all_clicked: ', eventData);
  pushDataLayer(eventData);
}

/**
 * When search button is clicked inside Search widget
 * @param {string} screenName
 * @param {object} pickupBranch
 * @param {object} dropoffBranch
 * @param {Date} pickupDate
 * @param {Date} dropoffDate
 * @param {string} pickupTime
 * @param {string} dropoffTime
 * @param {number} activeTabIndex
 * @param {boolean} isDropOffBranchSame
 */
export function searchTapped(
  screenName,
  pickupBranch,
  dropoffBranch,
  pickupDate,
  dropoffDate,
  pickupTime,
  dropoffTime,
  activeTabIndex,
  isDropOffBranchSame,
) {
  const pickupBranchName = _get(pickupBranch, 'nameEn', '').toLowerCase();
  const dropoffBranchName = _get(dropoffBranch, 'nameEn', '').toLowerCase();
  const formattedPickupDate = formatISO(pickupDate);
  const formattedDropoffDate = formatISO(dropoffDate);
  const bookingType = _get(pickupBranch, 'carDelivery', false);

  // convert time in 24h format.
  const formatPickupTime = get24hTime(pickupTime);
  const formatDropoffTime = get24hTime(dropoffTime);

  const eventData = {
    event: 'SEO_search_tapped',
    screen_name: screenName,
    pickup_branch_name: pickupBranchName,
    dropoff_branch_name: dropoffBranchName,
    pickup_date: formattedPickupDate,
    dropoff_date: formattedDropoffDate,
    pickup_time: formatPickupTime,
    dropoff_time: formatDropoffTime,
    short_term: !activeTabIndex,
    dropoff_branch_different: !isDropOffBranchSame,
    booking_type: bookingType ? 'delivery' : 'pickup',
  };
  console.log('** gtm event SEO_search_tapped: ', eventData);
  pushDataLayer(eventData);
}

/**
 * When an airport branch is clicked
 * @param {string} screenName
 * @param {string} name
 */
export function airportBranchClicked(screenName, name) {
  const eventData = {
    event: 'SEO_branch_clicked',
    screen_name: screenName,
    branch_name: name,
    branch_type: 'airport branch',
  };
  console.log('** gtm event SEO_branch_clicked: ', eventData);
  pushDataLayer(eventData);
}

/**
 * When an airport branch's direction is clicked
 * @param {string} screenName
 * @param {string} name
 */
export function branchDirectionsClicked(screenName, name) {
  const eventData = {
    event: 'SEO_branch_directions_clicked',
    screen_name: screenName,
    branch_name: name,
    branch_type: 'airport branch',
  };
  console.log('** gtm event SEO_branch_directions_clicked: ', eventData);
  pushDataLayer(eventData);
}

/**
 * When breadcrumb button is clicked
 * @param {string} screenName
 * @param {string} tabName
 */
export function breadcrumbClicked(screenName, tabName) {
  const eventData = {
    event: 'SEO_breadcrumb_clicked',
    screen_name: screenName,
    name_of_tab: tabName,
  };
  console.log('** gtm event SEO_breadcrumb_clicked: ', eventData);
  pushDataLayer(eventData);
}

/**
 * When search button is clicked under fleet carousel
 * @param {string} screenName
 */
export function searchClicked(screenName) {
  const eventData = {
    event: 'SEO_search_clicked_carousel',
    screen_name: screenName,
  };
  console.log('** gtm event SEO_search_clicked_carousel: ', eventData);
  pushDataLayer(eventData);
}
